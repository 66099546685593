import React from 'react';
import { Redirect, Router } from '@reach/router';
import SignIn from '../pages/SignIn';
import HomePage from '../pages/HomePage';
import Logout from '../pages/Logout';
import IntegrationDetailPage from '../pages/IntegrationDetailPage';
import IntegrationsPage from '../pages/IntegrationsPage';
import IntegrationCreatePage from '../pages/IntegrationCreatePage';
import LocationSettingsPage from '../pages/LocationSettingsPage';
import CommandPage from '../pages/CommandsPage';
import LogInspectorPage from '../pages/LogInspectorPage';
import { encryptStorage } from '../utils/storage';
import ArchivePage from '../pages/ArchivePage';
import IntegrationEditPage from '../pages/IntegrationEditPage';

function Routes() {
  const userSession = encryptStorage.getItem('userSession');
  const authToken = encryptStorage.getItem('authToken');

  const routes = [
    { component: IntegrationsPage, path: '/integrations' },
    { component: IntegrationDetailPage, path: '/integrations/:id' },
    { component: IntegrationCreatePage, path: '/integrations/create' },
    { component: LocationSettingsPage, path: '/location_settings/:id' },
    { component: CommandPage, path: '/toolkit/commands' },
    { component: LogInspectorPage, path: '/toolkit/log_inspector/*traceId' },
    { component: ArchivePage, path: '/integrations/archive' },
    { component: IntegrationEditPage, path: '/integrations/:id/edit' },
  ];

  return (
    <div>
      <Router>
        {userSession ? (
          <Redirect from="/sign_in" to="/integrations" />
        ) : (
          <SignIn path="sign_in" />
        )}
        <Logout path="logout" />
        <HomePage path="/">
          <Redirect from="/" to="/integrations" />
          {routes.map(({ component: Component, path }) =>
            userSession && authToken ? (
              <Component path={path} key={path} />
            ) : (
              <Redirect noThrow from={path} key={path} to="/sign_in" />
            ),
          )}
        </HomePage>
      </Router>
    </div>
  );
}

export default Routes;
