import React from 'react';
import { PropTypes } from 'prop-types';
import { navigate, Redirect } from '@reach/router';

import PuzzlePiece from '@decisiv/iconix/lib/components/PuzzlePiece';
import PlusCircle from '@decisiv/iconix/lib/components/PlusCircle';
import Grid from '@decisiv/iconix/lib/components/Grid';

import {
  Avatar,
  Menu,
  TopNav,
  Button,
  Flex,
  LeftNav,
  useStickyLeftNav,
} from '@decisiv/ui-components/lib/components';

import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

import NavFooter from '../components/NavFooter';
import Paper from '../components/Paper';

import logo from '../images/logo.png';
import { encryptStorage } from '../utils/storage';
import '../styles/HomePage.css';

const Container = styled(Flex).attrs({ as: 'section' })`
  background-color: ${toColorString(color.base.halfMoon)};
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 0;
`;

export default function HomePage({ children }) {
  const userSession = encryptStorage.getItem('userSession');
  const userName = `${userSession?.first_name} ${userSession?.last_name}`;

  /* eslint-disable no-unused-vars */
  const [{ isExpanded, navExpandedState }, handleChangeNavExpandedState] =
    useStickyLeftNav({
      getPreferredNavExpandedState: () => localStorage.getItem('LeftNavState'),
      setPreferredNavExpandedState: (state) =>
        state
          ? localStorage.setItem('LeftNavState', state)
          : localStorage.removeItem('LeftNavState'),
    });

  const items = [
    {
      icon: Grid,
      text: 'Integrations',
      path: '/integrations',
      items: [
        {
          text: 'Archive',
          path: '/integrations/archive',
        },
      ],
    },
    {
      icon: PuzzlePiece,
      text: 'Toolkit',
      items: [
        {
          text: 'Commands',
          path: '/toolkit/commands',
        },
        {
          text: 'Log Inspector',
          path: '/toolkit/log_inspector',
        },
      ],
    },
  ];

  const userMenuItems = [
    { id: 'user-profile', text: 'Profile', linkTo: 'user' },
    { id: 'logout', text: 'Logout', linkTo: '/logout' },
  ];

  return (
    <Paper css="height: 100%" padding={0}>
      {userSession ? (
        <Flex alignItems="top" flexDirection="row" padding={0}>
          <Flex flexDirection="column">
            <LeftNav
              currentPath={window.location.pathname}
              items={items}
              navExpandedState={navExpandedState}
              onChangeNavExpandedState={handleChangeNavExpandedState}
              name="leftNaveToogle"
            />
            <NavFooter navExpandedState={isExpanded} />
          </Flex>

          <Paper padding={0}>
            <TopNav className="StretchedTopNav">
              <Flex justifyContent="space-between" style={{ width: '100%' }}>
                <Flex paddingX={1}>
                  <TopNav.BrandInfo
                    logo={logo}
                    primaryText="Decisiv"
                    secondaryText="Integration Framework"
                  />
                </Flex>

                <Flex style={{ marginLeft: 'auto' }}>
                  <Flex marginTop={1.3}>
                    <Button
                      text="CREATE INTEGRATION"
                      icon={PlusCircle}
                      iconPosition="left"
                      size="medium"
                      kind="secondary"
                      variant="inverted"
                      onClick={() => navigate('/integrations/create')}
                    />
                  </Flex>
                  <Flex>
                    <TopNav.Menu
                      emblem={(emblemProps) => (
                        <Avatar
                          {...emblemProps}
                          name={userName}
                          indicatorColor="information"
                        />
                      )}
                      primaryText={userName}
                    >
                      <Menu items={userMenuItems} />
                    </TopNav.Menu>
                  </Flex>
                </Flex>
              </Flex>
            </TopNav>
            <Container alignItems="left" flexDirection="column" paddingY={0.5}>
              {children}
            </Container>
          </Paper>
        </Flex>
      ) : (
        <Redirect from="/" to="sign_in" />
      )}
    </Paper>
  );
}

HomePage.propTypes = {
  children: PropTypes.node.isRequired,
};
